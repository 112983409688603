import React from 'react';
import Layout from '../../../components/layout';
import RegistrationFrom from '../../../components/registration-form/registration-from';

export default function RegisterUnido(props) {
  return (
    <Layout>
      <RegistrationFrom
        imageName='unido24'
        imageAlt='unido24'
        source='UNIDO24'
        page='unido24'
      />
    </Layout>
  );
}
